export default {
  public: [],
  admin: [
    // 訂單列表
    {
      path: 'order',
      name: 'order',
      component: () => import('@/modules/order/views/orderList.vue'),
    },

    // 訂單表單
    {
      path: 'order/create',
      name: 'order-create',
      component: () => import('@/modules/order/views/createOrderAdmin.vue'),
    },
    {
      path: 'order/update/:target',
      name: 'order-update',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/order/views/orderForm.vue'),
    },
    {
      path: 'pay-return/:id',
      name: 'pay-return',
      component: () => import('@/modules/order/views/payReturn.vue'),
    },
    {
      path: 'ecpay-delivery-print/:id',
      name: 'ecpay-delivery-print',
      component: () => import('@/modules/order/views/ecpayDeliveryPrint.vue'),
    },


    {
      path: 'app-config/order',
      name: 'app-config-order',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/order/views/orderAppConfigForm.vue'),
    },
  ],
}
